<template>
  <!-- 新增&编辑会议信息弹窗 -->
  <base-dialog
    :visible.sync="currentVisible"
    width="1000px"
    :showFooter="false"
    :title="dialogTitle"
    class="dialog"
  >
    <div class="addProcess">
      <base-form
        :componentList="addForm"
        :formAttrs="{
          model: addQueryParas,
          labelWidth: '140px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="addFormData"
      >
      </base-form>
    </div>
    <template slot="footer">
      <base-button label="提 交" @click="submit" v-if="!showInfo"></base-button>
      <base-button
        label="关 闭"
        type="default"
        @click="
          () => {
            this.currentVisible = false;
          }
        "
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>

// import { formatDate } from '@/utils/auth/common'
import { meetApi, businessManageApi } from '@/api/businessApi'
import baseForm from '@/components/common/base-form/base-form.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { addForm } from '../utils/config'
export default {
  components: { baseForm, BaseDialog, BaseButton },
  name: 'addEditDialog',
  props: {
    dialogTitle: String,
    visible: Boolean,
    findById: String,
    showInfo: Boolean
  },
  data () {
    return {
      pass: false,
      addQueryParas: { startMeetingDate: '', endMeetingDate: '', reviewStatus: '1', meetingBatches: [] }
    }
  },
  computed: {
    businessManageApi () {
      return businessManageApi
    },
    api () {
      return meetApi
    },
    addForm () {
      return addForm(this)
    },
    currentVisible: {
      get () {
        if (this.findById) {
          this.getFindById()
        } else {
          this.show()
        }

        return this.visible
      },
      set (val) {
        this.addQueryParas = { startMeetingDate: '', endMeetingDate: '', reviewStatus: '1', meetingBatches: [] }
        this.$emit('update:showInfo', val)
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    currentVisible () {
      this.$nextTick(() => {
        this.$refs.addFormData.clearValidate()
      })
    }
  },
  methods: {
    show () {
      if (this.showInfo) {
        this.pass = true
      }
      if (this.addQueryParas.meetingBatches.length > 0) {
        this.addQueryParas.meetingBatches.forEach((item) => {
          if (item.useFlag) {
            this.pass = true
          }
        })
      } else {
        this.pass = false
      }
      console.log(this.pass, 'this.pass')
    },
    // 修改开始时间
    changeStartMeetingDate () {
      this.$set(this.addQueryParas, 'endMeetingDate', '')
    },
    // 修改时间
    changeDate (data) {
      if (data) {
        if (this.addQueryParas.meetingBatches.length > 0) {
          this.addQueryParas.meetingBatches.forEach((item, index) => {
            item.meetingBatchNo = data.replace(/-/g, '').replace(/\//g, '') + (index + 1)
          })
        }
      }
    },
    // 修改供应商
    changeSupplier (data, index) {
      this.businessManageApi.getGys({ gysId: data }).then(res => {
        const option = []
        res.data.forEach((item) => {
          option.push({ keyId: item.businessNo, label: item.businessNo, businessNo: item.keyId })
        })

        this.$set(this.addQueryParas.meetingBatches[index], 'options', option)
      })
    },
    // 获取会议信息
    getFindById () {
      this.api.getFindById({ keyId: this.findById }).then(res => {
        // res.data.endMeetingDate = formatDate(res.data.endMeetingDate, 'YY-MM-DD')
        // res.data.startMeetingDate = formatDate(res.data.startMeetingDate, 'YY-MM-DD')
        this.addQueryParas = res.data
        this.addQueryParas.reviewStatus = '1'
        if (this.addQueryParas.meetingBatches) {
          this.addQueryParas.meetingBatches.forEach((item, index) => {
            this.changeSupplier(item.gysId, index)
          })
        }
        this.show()
        this.$emit('update:findById', '')
      })
    },
    changeValidTimeUnit (data) {
      console.log(data)
      this.$set(this.addQueryParas, 'validTimeUnit', data)
    },
    // 提交新增修改
    submit () {
      this.$refs.addFormData.validate((valid) => {
        if (valid) {
          if (this.addQueryParas.meetingBatches.length === 0) {
            this.warning('请完善会议批次信息')
            return
          } else {
            let pass = true
            this.addQueryParas.meetingBatches.forEach((item) => {
              if (!item.businessNo || !item.gysName || !item.meetingBatchNo) {
                pass = false
              }
            })
            if (!pass) {
              this.warning('请完善会议批次信息')
              return
            }
          }
          const data = this.addQueryParas
          this.api.save(data).then(res => {
            if (res.data) {
              this.success('新增成功')
              this.$emit('handel', '')
              this.currentVisible = false
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  /deep/ .el-form-item__content {
    height: auto !important;
  }
</style>
